<template>
  <pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <simple-map :mapHeight="'250px'"> <stationMap :sensor="sensor" /> </simple-map>
    </template>

    <template v-slot:info>
      <stationInfo :sensor="sensor" />
    </template>

   
    <b-tabs class="tab-card">
      <b-tab title="24h" active>
          <stationSummary :stationId="sensorId" />
      </b-tab>

      <b-tab title="Histórico" :lazy="historyLazy" @click="historyLazy=false">
        <stationHistory :sensor="sensor" />
      </b-tab>

      <!-- <b-tab title="DeltaT">
        <deltaTChart  :humidity="sensor.humidity"  :temperature="sensor.temperature"/>
      </b-tab> -->

    </b-tabs>

  </pageTemplate>
</template>

<script>

import { DateTime } from "luxon";
import station from "./station";
import stationInfo from "./stationInfo.vue";
import stationSummary from "./stationSummary.vue"


import stationMap from "./stationMap";
import stationHistory from "./stationHistoryPage.vue"

import pageTemplate from "../sensorDetailsTemplate";

// import deltaTChart from "./deltaT/deltaTChart.vue";

export default {
  extends: station,

  data() {
    return {
      historyLazy : true
    };
  },

  computed: {
    startDateTime() {
      return DateTime.local()
        .plus({ hours: -24 })
        .toJSDate();
    },
    endDateTime() {
      return DateTime.local().toJSDate();
    },
  },

 


 

  components: {
    stationInfo,
    stationHistory,
    stationMap,
    pageTemplate,
    stationSummary,
    // deltaTChart
  },
};
</script>

<style lang="scss" scoped>
.weather-chart {
  margin: 1rem 0px;
  padding: 2px;
  border: 1px #aaa solid;
  border-radius: 5px;
}
.weather-table {
  margin: 1rem 0px;
}

.generic-chart {
  background-color: white;
  margin-bottom: 15px;
  border: 1px #aaa solid;
  border-radius: 5px;
}
</style>
