<template>
  <div>

  <json-editor v-model="remoteconfig"></json-editor>

   

    <h6> Templates </h6>
    <b-button-toolbar class="mb-2 buttons-container" size="sm">
        <b-button variant="outline-primary"  @click="remoteconfig = pluvioTemplate">  Pluvio </b-button>
        <b-button variant="outline-primary" @click="remoteconfig  = soloTemplate">    Solo </b-button>
        <b-button variant="outline-primary" @click="remoteconfig  = stationTemplate"> Estação </b-button>
        <b-button variant="outline-primary"  @click="remoteconfig = hidrometerTemplate">  Hidrômetro </b-button>
        <b-button variant="outline-primary" @click="remoteconfig  = levelTemplate">   Nível </b-button>
        <b-button variant="outline-primary" @click="remoteconfig  = routerTemplate">   Repetidor </b-button>
    </b-button-toolbar>


    <hr />

    <div class="buttons-container ml-auto">
      <b-button @click="$emit('cancel')" variant="secondary">
        Cancelar
      </b-button>
      <b-button
        v-if="saveStatus == 'saved'"
        @click="saveConfig()"
        variant="success"
      >
        Salvar
      </b-button>
      <b-button v-else variant="secoundary">
        salvando...
        <font-awesome-icon icon="spinner" spin />
      </b-button>
    </div>

    <!-- {{ saveStatus }} -->
    <!-- {{remoteconfig}} -->
  </div>
</template>

<script>

import remoteConfigTemplate from "./remoteConfigTemplate.json"

export default {
  data() {
    return {
      saveStatus: "saved",
      remoteconfig: {},
      opt: {
        mode: "tree",
        modes: ["code", "tree"],
      },
    };
  },

  props: {
    feed: { type: Object, required: true },
  },

 
  mounted() {
    this.remoteconfig = JSON.parse(this.feed.nodeConfig);
  },

  computed:{
    pluvioTemplate(){
      return remoteConfigTemplate["pluvio"]
    },
    soloTemplate(){
      return remoteConfigTemplate["solo"]
    },
    stationTemplate(){
      return remoteConfigTemplate["station"]
    },
    routerTemplate(){
      return remoteConfigTemplate["router"]
    },
    hidrometerTemplate(){
      return remoteConfigTemplate["hidrometer"]
    },
    levelTemplate(){
      return remoteConfigTemplate["level"]
    }

  },

  methods: {
    onError() {
      //   console.log(v);
    },



    saveConfig() {
      this.saveStatus = "saving";
      this.$http
        .put("/sens/feed/" + this.feed.id + "/nodeConfig", this.remoteconfig)
        .then(
          () => {
            this.saveStatus = "saved";
            this.ScreenMode = "details";
            this.$bvToast.toast("Configurações do sensor salvas", {
              title: "status",
              variant: "success",
              solid: true,
            });
          },
          (error) => {
            console.log("load feed error:", error);
            this.saveStatus = "error";
            this.$bvToast.toast("As configurações não foram salvas", {
              title: "status",
              variant: "danger",
              solid: true,
            });
          }
        );
    },
  },
};
</script>

